<template>
    <div>
        <div class="alert alert-info" role="alert" v-if="count == 0">Pas d'attestations disponibles</div>
        <div v-else>
            <div class="alert alert-success" role="alert">Attestations disponibles</div>
            <ul>
                <li v-for="fichier in fichiers" :key="fichier.id">
                    <i class="fa fa-file"></i> <a href="#" @click="loadfile(fichier.id, fichier.cat, fichier.nom)">{{
                        fichier.datecreate + ' ' +
                        fichier.Description }}</a>
                </li>
            </ul>
        </div>
        <br /><br />
    </div>
</template>

<script>
import store from "../store";
import { fileApi, getPrefix, selectApi } from "../lib/api.js";
import moment from "moment";

export default {
    name: "Formation",
    store: store,
    data() {
        return {
            count: 0,
            fichiers: [],
            nomfichier: ''
        };
    },
    methods: {
        loadfile(idFile, cat, nom) {
            let id = store.state.idUser;
            if (cat != 253) {
                fileApi(this, id, idFile).then(
                    (response) => {
                        this.nomfichier = response.data.result.replace('./public', getPrefix());
                        window.open(this.nomfichier, '_blank');
                    },
                    (response) => {
                    }
                );
            } else {
                this.nomfichier = getPrefix() + '/video/' + nom;
                console.log(this.nomfichier);
                window.open(this.nomfichier, '_blank');
            }
        },
        refresh() {
            let id = store.state.idUser;
            let sql =
                "SELECT f.id,f.datecreate,f.nom,f.Description,f.idcategorie as cat FROM fichier AS f WHERE f.idclient=" +
                id +
                " AND f.web=1 AND f.idcategorie=358";
            selectApi(this, sql).then(
                (response) => {
                    this.count = response.data.count;
                    this.fichiers = response.data.result;

                    this.fichiers.forEach(item => {
                        item.datecreate = moment(item.datecreate).format("DD/MM/YYYY");
                    });
                },
                (response) => {
                }
            );
        },
    },
    mounted() {
        if (store.state.typeUser == 0) {
            this.$router.push({ name: "login" });
        }
        this.refresh();
    },
};
</script>

<style lang="scss">
@import "src/assets/scss/design.scss";

ul {
    list-style-type: none;
}
</style>